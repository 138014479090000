import { useState, useCallback, useEffect } from 'react';
import { supabase } from '../config/supabaseClient';

export default function useMapFilters(initialFilters, updateFilters, filteredCategories, filteredSubCategories) {
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);

  // Fetch provinces on mount
  useEffect(() => {
    const fetchProvinces = async () => {
      const { data, error } = await supabase
        .from('provinces')
        .select('name')
        .eq('country_code', 'ca')
        .order('name');

      if (error) {
        console.error('Error fetching provinces:', error);
        return;
      }

      if (data) {
        const provinceNames = data.map(province => province.name).filter(Boolean);
        setProvinces(provinceNames);
      }
    };

    fetchProvinces();
  }, []);

  // Load province from cookie on mount
  useEffect(() => {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    };

    const savedProvince = getCookie('lastProvince');
    if (savedProvince && !initialFilters.province) {
      updateFilters(prev => ({
        ...prev,
        province: savedProvince
      }));
    }
  }, [initialFilters.province, updateFilters]);

  const fetchCities = useCallback(async (province) => {
    if (!province) {
      setCities([]);
      return;
    }

    const { data, error } = await supabase
      .from('places')
      .select('city')
      .eq('province', province)
      .not('city', 'is', null);

    if (error) {
      console.error('Error fetching cities:', error);
      return;
    }

    if (data) {
      const uniqueCities = [...new Set(data.map(place => place.city))]
        .filter(Boolean)
        .sort();
      setCities(uniqueCities);
    }
  }, []);

  // Fetch cities whenever initialFilters.province changes
  useEffect(() => {
    if (initialFilters.province) {
      fetchCities(initialFilters.province);
    }
  }, [initialFilters.province, fetchCities]);

  const handleProvinceChange = useCallback((province) => {
    // Save province to cookie
    if (province) {
      document.cookie = `lastProvince=${province};path=/;max-age=31536000`; // 1 year
    }

    updateFilters({
      province,
      city: '',
      category: '',
      subCategory: ''
    });
    if (!province) {
      setCities([]);
      // Clear province cookie if province is cleared
      document.cookie = 'lastProvince=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
  }, [updateFilters]);

  const handleCityChange = useCallback((city) => {
    updateFilters(prev => ({
      ...prev,
      city
    }));
  }, [updateFilters]);

  const handleCategoryChange = useCallback((category) => {
    updateFilters(prev => ({
      ...prev,
      category,
      subCategory: ''
    }));
  }, [updateFilters]);

  const handleSubCategoryChange = useCallback((subCategory) => {
    updateFilters(prev => ({
      ...prev,
      subCategory
    }));
  }, [updateFilters]);

  const handleTagRemove = useCallback((key) => {
    let newFilters;
    
    switch (key) {
      case 'province':
        newFilters = {
          province: '',
          city: '',
          category: '',
          subCategory: ''
        };
        setCities([]);
        // Clear province cookie
        document.cookie = 'lastProvince=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        break;
      case 'city':
        newFilters = prev => ({
          ...prev,
          city: ''
        });
        break;
      case 'category':
        newFilters = prev => ({
          ...prev,
          category: '',
          subCategory: ''
        });
        break;
      case 'subCategory':
        newFilters = prev => ({
          ...prev,
          subCategory: ''
        });
        break;
      default:
        return;
    }
    
    updateFilters(newFilters);
  }, [updateFilters]);

  const resetFilters = useCallback(() => {
    // Keep the province and cities when resetting, just clear selections
    updateFilters(prev => ({
      ...prev,
      city: '',
      category: '',
      subCategory: ''
    }));
  }, [updateFilters]);

  return {
    provinces,
    cities,
    handleProvinceChange,
    handleCityChange,
    handleCategoryChange,
    handleSubCategoryChange,
    handleTagRemove,
    resetFilters,
    fetchCities
  };
}
