import React, { useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Combobox } from '@headlessui/react';

const MapFilters = ({
  isMobile,
  isFilterDrawerOpen,
  filters,
  provinces,
  cities,
  filteredCategories,
  filteredSubCategories,
  handleProvinceChange,
  handleCityChange,
  handleCategoryChange,
  handleSubCategoryChange,
  handleClearProvince,
  handleClearCategory,
}) => {
  const [cityQuery, setCityQuery] = useState('');

  const filteredCities = cityQuery === ''
    ? cities
    : cities.filter((city) =>
        city.toLowerCase().includes(cityQuery.toLowerCase())
      );

  const renderCombobox = () => (
    <Combobox value={filters.city} onChange={handleCityChange} disabled={!filters.province}>
      <div className="relative">
        <Combobox.Input
          className="w-full pl-3 pr-8 py-2 border border-gray-300 rounded-lg text-sm bg-white focus:outline-none focus:ring-1 focus:ring-[#FFCC4A] disabled:bg-gray-100 disabled:cursor-not-allowed"
          placeholder={!filters.province ? "Choose a Province first" : "Search or select a city"}
          displayValue={(city) => city}
          onChange={(event) => setCityQuery(event.target.value)}
          disabled={!filters.province}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronDownIcon className="h-4 w-4 text-gray-500" />
        </Combobox.Button>
      </div>
      <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
        {filteredCities.length === 0 && cityQuery !== '' ? (
          <div className="relative cursor-default select-none py-2 px-3 text-gray-700 text-sm">
            Nothing found.
          </div>
        ) : (
          <>
            <Combobox.Option value="" className={({ active }) =>
              `relative cursor-default select-none py-2 pl-3 pr-9 text-sm ${
                active ? 'bg-[#FFCC4A] text-gray-900' : 'text-gray-900'
              }`
            }>
              All Cities
            </Combobox.Option>
            {filteredCities.map((city) => (
              <Combobox.Option
                key={city}
                value={city}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-3 pr-9 text-sm ${
                    active ? 'bg-[#FFCC4A] text-gray-900' : 'text-gray-900'
                  }`
                }
              >
                {city}
              </Combobox.Option>
            ))}
          </>
        )}
      </Combobox.Options>
    </Combobox>
  );

  if (isMobile) {
    return (
      <div className={`mt-2 space-y-2 ${isFilterDrawerOpen ? 'block' : 'hidden'}`}>
        <div className="relative">
          <select
            value={filters.province || ''}
            onChange={(e) => handleProvinceChange(e.target.value)}
            className="w-full appearance-none pl-3 pr-8 py-2 border border-gray-300 rounded-lg text-sm bg-white focus:outline-none focus:ring-1 focus:ring-[#FFCC4A]"
          >
            <option value="">All Provinces</option>
            {provinces.map(province => province && (
              <option key={province} value={province}>{province}</option>
            ))}
          </select>
          <ChevronDownIcon className="absolute right-2 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500 pointer-events-none" />
        </div>

        <div className="relative">
          {renderCombobox()}
        </div>

        <div className="relative">
          <select
            value={filters.category || ''}
            onChange={(e) => handleCategoryChange(e.target.value)}
            className="w-full appearance-none pl-3 pr-8 py-2 border border-gray-300 rounded-lg text-sm bg-white focus:outline-none focus:ring-1 focus:ring-[#FFCC4A]"
          >
            <option value="">All Categories</option>
            {filteredCategories.map(category => (
              <option key={category.id} value={category.name}>{category.name}</option>
            ))}
          </select>
          <ChevronDownIcon className="absolute right-2 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500 pointer-events-none" />
        </div>

        <div className="relative">
          <select
            value={filters.subCategory || ''}
            onChange={(e) => handleSubCategoryChange(e.target.value)}
            className="w-full appearance-none pl-3 pr-8 py-2 border border-gray-300 rounded-lg text-sm bg-white focus:outline-none focus:ring-1 focus:ring-[#FFCC4A]"
            disabled={!filters.category}
          >
            {!filters.category ? (
              <option value="">Choose a Category first</option>
            ) : (
              <>
                <option value="">All Sub-Categories</option>
                {filteredSubCategories.map(subCategory => (
                  <option key={subCategory.id} value={subCategory.name}>{subCategory.name}</option>
                ))}
              </>
            )}
          </select>
          <ChevronDownIcon className="absolute right-2 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500 pointer-events-none" />
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-4 gap-2 mt-2">
      <div className="relative">
        <select
          value={filters.province || ''}
          onChange={(e) => handleProvinceChange(e.target.value)}
          className="w-full appearance-none pl-3 pr-8 py-2 border border-gray-300 rounded-lg text-sm bg-white focus:outline-none focus:ring-1 focus:ring-[#FFCC4A]"
        >
          <option value="">All Provinces</option>
          {provinces.map(province => province && (
            <option key={province} value={province}>{province}</option>
          ))}
        </select>
        <ChevronDownIcon className="absolute right-2 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500 pointer-events-none" />
      </div>

      <div className="relative">
        {renderCombobox()}
      </div>

      <div className="relative">
        <select
          value={filters.category || ''}
          onChange={(e) => handleCategoryChange(e.target.value)}
          className="w-full appearance-none pl-3 pr-8 py-2 border border-gray-300 rounded-lg text-sm bg-white focus:outline-none focus:ring-1 focus:ring-[#FFCC4A]"
        >
          <option value="">All Categories</option>
          {filteredCategories.map(category => (
            <option key={category.id} value={category.name}>{category.name}</option>
          ))}
        </select>
        <ChevronDownIcon className="absolute right-2 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500 pointer-events-none" />
      </div>

      <div className="relative">
        <select
          value={filters.subCategory || ''}
          onChange={(e) => handleSubCategoryChange(e.target.value)}
          className="w-full appearance-none pl-3 pr-8 py-2 border border-gray-300 rounded-lg text-sm bg-white focus:outline-none focus:ring-1 focus:ring-[#FFCC4A]"
          disabled={!filters.category}
        >
          {!filters.category ? (
            <option value="">Choose a Category first</option>
          ) : (
            <>
              <option value="">All Sub-Categories</option>
              {filteredSubCategories.map(subCategory => (
                <option key={subCategory.id} value={subCategory.name}>{subCategory.name}</option>
              ))}
            </>
          )}
        </select>
        <ChevronDownIcon className="absolute right-2 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500 pointer-events-none" />
      </div>
    </div>
  );
};

export default MapFilters;
