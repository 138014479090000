import React, { useState, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  ChevronRightIcon,
  ShareIcon,
  HeartIcon,
  PhoneIcon,
  EnvelopeIcon,
  GlobeAltIcon
} from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFacebook,
  faInstagram 
} from '@fortawesome/free-brands-svg-icons';
import toast from 'react-hot-toast';

function Place({ place, onPlaceClick, onPhotoClick, isFavorite, addFavorite, removeFavorite, isSignedIn, onShare }) {
  const [imgSrc, setImgSrc] = useState(
    place.photo || 'https://tbvywbqdzxskcrephgby.supabase.co/storage/v1/object/public/photos/altgrocery-default.webp'
  );

  const location = useLocation();

  const handleImageError = () => {
    setImgSrc('https://tbvywbqdzxskcrephgby.supabase.co/storage/v1/object/public/photos/altgrocery-default.webp');
  };

  const handlePlaceClick = () => {
    onPlaceClick(place);
  };

  const toggleFavorite = useCallback((e) => {
    e.stopPropagation();
    if (isSignedIn) {
      const action = isFavorite ? removeFavorite : addFavorite;
      const message = isFavorite
        ? `Removed ${place.name} from favorites.`
        : `Added ${place.name} to favorites!`;
      
      toast(message, {
        icon: isFavorite ? '💔' : '❤️',
        duration: 1500,
      });

      action(isFavorite ? place.id : place).catch(error => {
        console.error('Error toggling favorite:', error);
        toast.error('Failed to update favorites. Please try again.');
      });
    } else {
      toast.error('Please sign in to add favorites.', { duration: 2000 });
    }
  }, [isFavorite, place, addFavorite, removeFavorite, isSignedIn]);

  const handleShare = (e) => {
    e.stopPropagation();
    onShare(place);
  };

  const renderPlaceName = () => {
    const countryCode = window.location.pathname.split('/')[1];
    const province = (place.province || 'unknown').toLowerCase();
    const identifier = place.slug || place.id;
    const searchParams = new URLSearchParams(location.search);
    const city = searchParams.get('city');
    const category = searchParams.get('category');
    const subCategory = searchParams.get('subCategory');

    const state = {
      province,
      city,
      category,
      subCategory
    };

    const nameContent = (
      <span className="text-[1rem] font-semibold text-gray-900">
        {place.name}
      </span>
    );

    return isSignedIn ? (
      <Link
        to={`/${countryCode}/${province}/place/${identifier}`}
        state={state}
        className="hover:underline cursor-pointer"
        onClick={(e) => e.stopPropagation()}
      >
        {nameContent}
      </Link>
    ) : (
      nameContent
    );
  };

  return (
    <div
      className="place-card flex md:flex-col cursor-pointer bg-white rounded-lg shadow-sm"
      onClick={handlePlaceClick}
    >
      <div className="relative w-1/3 md:w-full md:mb-3">
        <div className="place-card-image-container md:pt-[66.67%] pt-0 h-24 md:h-auto">
          <img
            src={imgSrc}
            alt={place.name}
            className="place-card-image rounded-l-lg md:rounded-lg"
            onError={handleImageError}
            onClick={(e) => {
              e.stopPropagation();
              onPhotoClick(place);
            }}
          />
        </div>
        <div className="absolute inset-0 bg-black opacity-0 transition-opacity duration-300 ease-in-out rounded-l-lg md:rounded-lg"></div>
        {isSignedIn && (
          <button
            onClick={toggleFavorite}
            className="absolute top-1 right-1 md:top-2 md:right-2 bg-white bg-opacity-90 rounded-full w-6 h-6 md:w-8 md:h-8 flex items-center justify-center transition-colors duration-200 hover:bg-opacity-100"
            aria-label={isFavorite ? "Remove from favorites" : "Add to favorites"}
          >
            <HeartIcon 
              className={`w-4 h-4 md:w-5 md:h-5 ${isFavorite ? 'text-red-500' : 'text-gray-400'} transition-colors duration-200`} 
            />
          </button>
        )}
        <button
          onClick={handleShare}
          className="absolute top-1 left-1 md:top-2 md:left-2 bg-white bg-opacity-90 rounded-full w-6 h-6 md:w-8 md:h-8 flex items-center justify-center hover:bg-opacity-100"
          aria-label="Share"
        >
          <ShareIcon className="w-4 h-4 md:w-5 md:h-5 text-gray-600" />
        </button>
      </div>
      <div className="place-card-content flex-1 p-2 md:p-3">
        <p className="text-gray-600 text-xs md:text-sm font-medium">
          {place.category}
          {place.sub_category && (
            <>
              <ChevronRightIcon className="w-3 h-3 md:w-4 md:h-4 mx-1 inline-block align-[-2px]" />
              {place.sub_category}
            </>
          )}
        </p>
        {renderPlaceName()}
        <p className="text-gray-600 text-xs md:text-sm mt-1">{place.address}</p>
        
        <div className="flex flex-wrap items-center gap-3 md:gap-4 mt-2">
          {place.phone && (
            <a 
              href={`tel:${place.phone}`} 
              aria-label="Phone" 
              className="text-gray-500 hover:text-gray-700 transition-colors duration-200 inline-flex items-center justify-center" 
              onClick={(e) => e.stopPropagation()}
              title={place.phone}
            >
              <PhoneIcon className="w-4 h-4 md:w-[18px] md:h-[18px] stroke-2" />
            </a>
          )}
          {place.email && (
            <a 
              href={`mailto:${place.email}`} 
              aria-label="Email" 
              className="text-gray-500 hover:text-gray-700 transition-colors duration-200 inline-flex items-center justify-center" 
              onClick={(e) => e.stopPropagation()}
              title={place.email}
            >
              <EnvelopeIcon className="w-4 h-4 md:w-[18px] md:h-[18px] stroke-2" />
            </a>
          )}
          {place.website && (
            <a 
              href={place.website} 
              target="_blank" 
              rel="noopener noreferrer" 
              aria-label="Website" 
              className="text-gray-500 hover:text-gray-700 transition-colors duration-200 inline-flex items-center justify-center" 
              onClick={(e) => e.stopPropagation()}
              title="Visit website"
            >
              <GlobeAltIcon className="w-4 h-4 md:w-[18px] md:h-[18px] stroke-2" />
            </a>
          )}
          {place.facebook && (
            <a 
              href={place.facebook} 
              target="_blank" 
              rel="noopener noreferrer" 
              aria-label="Facebook" 
              className="text-gray-500 hover:text-[#1877f2] transition-colors duration-200 inline-flex items-center justify-center" 
              onClick={(e) => e.stopPropagation()}
              title="Visit Facebook page"
            >
              <FontAwesomeIcon icon={faFacebook} className="w-4 h-4 md:w-[18px] md:h-[18px]" />
            </a>
          )}
          {place.instagram && (
            <a 
              href={place.instagram} 
              target="_blank" 
              rel="noopener noreferrer" 
              aria-label="Instagram" 
              className="text-gray-500 hover:text-[#e4405f] transition-colors duration-200 inline-flex items-center justify-center" 
              onClick={(e) => e.stopPropagation()}
              title="Visit Instagram profile"
            >
              <FontAwesomeIcon icon={faInstagram} className="w-4 h-4 md:w-[18px] md:h-[18px]" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default Place;
