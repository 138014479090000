import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Announcement from '../components/Announcement';

const Roadmap = () => {
  return (
    <>
      <Announcement />
      <div className="bg-white min-h-screen">
        <Header />
        <main className="py-12">
          <div className="mx-auto px-4 sm:px-6 lg:px-8 max-w-4xl">
            <h1 className="text-4xl font-bold text-gray-900 mb-2 text-center">Product Roadmap</h1>
            <p className="text-sm text-gray-500 mb-8 text-center">Last updated: December 11, 2024</p>
            
            <div className="space-y-12">
              {/* UI Updates */}
              <section>
                <h2 className="text-2xl font-bold text-gray-900 mb-4">UI Updates (v2.1)</h2>
                <ul className="list-disc pl-6 space-y-3 text-gray-600">
                  <li><strong>Mobile-First UI</strong> Redesigned the interface to ensure a smoother and more user-friendly experience for mobile users.</li>
                  <li><strong>Search Bar</strong> Introduced a nationwide search bar, enabling users to easily find information across Canada.</li>
                  <li><strong>Icon Links:</strong> Reintroduced quick-access icon links for social media and contact information due to popular demand.</li>
                  <li><strong>Improved Pagination</strong>Replaced the lazy load pagination, which worked well only in larger cities, with a more intuitive manual pagination system. Dropdown filters now make it easier to navigate without scrolling to the bottom to trigger lazy loading.</li>
                </ul>
              </section>

              {/* Current Features */}
              <section>
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Current Features (v2.0)</h2>
                <ul className="list-disc pl-6 space-y-3 text-gray-600">
                  <li><strong>Business Directory:</strong> Comprehensive listings of independent food businesses across Canada</li>
                  <li><strong>Interactive Map:</strong> Visual exploration of local food alternatives in your community</li>
                  <li><strong>Crowdsourced Listings:</strong> Community-driven recommendations of local food businesses</li>
                  <li><strong>Recommend Your Favorites:</strong> Continue sharing your business or favorite local alternatives as we prepare to launch full profile pages and advanced functionality in our next update</li>
                </ul>
              </section>

              {/* Upcoming Features */}
              <section>
                <h2 className="text-2xl font-bold text-gray-900 mb-4">Upcoming Features (v3.0) - Spring 2025)</h2>
                <p className="text-gray-600 mb-4">
                  The next version will provide more robust support for businesses and consumers, including:
                </p>
                <ul className="list-disc pl-6 space-y-3 text-gray-600">
                  <li><strong>Subscription-Based Listings:</strong> Farmers, grocers, and markets can manage their profiles to keep their information current and accessible.</li>
                  <li><strong>Farmers Market Tools:</strong> Interactive maps showcasing vendor booths, products, hours, and payment methods.</li>
                  <li><strong>Grocery Score:</strong> A rating system evaluating businesses on affordability, quality, sustainability, and transparency.</li>
                  <li><strong>Marketing Tools:</strong> Digital flyers, promotions, and updates for businesses to engage directly with consumers.</li>
                  <li><strong>Analytics Dashboard:</strong> Insights to help businesses track visitor trends and engagement.</li>
                  <li><strong>Geolocation Pop-Up Feature:</strong> Real-time updates for seasonal farm stands and backyard gardeners.</li>
                  <li><strong>Farmer/Wholesaler to Grocer Connection Tool:</strong> Facilitating connections to help farmers and wholesalers get products on grocery shelves.</li>
                  <li><strong>U.S. Market Expansion:</strong> Extending our platform to include alternative grocers and growers in the United States, enabling cross-border opportunities.</li>
                </ul>
              </section>

              {/* News & Updates */}
              <section>
                <h2 className="text-2xl font-bold text-gray-900 mb-4">News & Updates</h2>
                <ul className="space-y-4 text-gray-600">
                  <li>• Maintaining free crowdsourced listings while introducing optional premium services for businesses</li>
                  <li>• Developing enhanced tools and features for premium subscribers</li>
                  <li>• Building partnerships with industry associations to strengthen platform capabilities</li>
                </ul>
              </section>
            </div>
          </div>
        </main>
        <Footer 
          customStyle={{ 
            maxWidth: '97rem', 
            margin: '0 auto'
          }} 
          textAlignLeft={true}
        />
      </div>
    </>
  );
};

export default Roadmap;
