import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import Announcement from '../components/Announcement';

const FAQItem = ({ question, answer, isOpen, onToggle }) => {
  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left focus:outline-none"
        onClick={onToggle}
      >
        <span className="text-lg font-medium text-gray-900" dangerouslySetInnerHTML={{ __html: question }}></span>
        <svg
          className={`w-6 h-6 transition-transform ${isOpen ? 'transform rotate-180' : ''}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>
      {isOpen && (
        <div className="mt-2 text-gray-600 text-base">
          {typeof answer === 'function' ? answer() : <p dangerouslySetInnerHTML={{ __html: answer }} />}
        </div>
      )}
    </div>
  );
};

const About = () => {
  const [openFAQIndex, setOpenFAQIndex] = useState(null);

  const faqs = [
    {
      question: "What is AltGrocery?",
      answer: "AltGrocery is a platform that connects consumers to alternative grocery options, such as local farms, farmers' markets, and specialty independent food stores."
    },
    {
      question: "How does AltGrocery work?",
      answer: "Users can search for alternative grocery options in their area, view detailed information about each location, and get directions. Vendors can list their businesses, update their profiles as needed, and engage with their future consumers."
    },
    {
      question: "Is AltGrocery available in my area?",
      answer: "AltGrocery is continuously expanding. A unique aspect of the platform is that it's crowd-sourced, allowing users to add new locations to the directory."
    },
    {
      question: "How can I list my business on AltGrocery?",
      answer: "Currently you can recommend a location through our Altgrocery.ca website here: <a href='https://www.altgrocery.ca/recommend-alt-grocer' target='_blank' rel='noopener noreferrer' class='text-blue-600 hover:text-blue-800 underline'>https://www.altgrocery.ca/recommend-alt-grocer</a>. This is still done manually but when we release the rest of this build you will be able to do it yourself."
    },
    {
      question: "Is AltGrocery free to use?",
      answer: "Yes, AltGrocery is free for both consumers and vendors. We believe in supporting local food systems and making alternative grocery options accessible to everyone."
    },
    {
      question: "I have a suggestion for a new feature or improvement. How can I share it?",
      answer: () => (
        <p>
          Visit our <Link to='/roadmap' className='text-blue-600 hover:text-blue-800 underline'>Roadmap page</Link> to submit new feature requests and upvote existing ones. We're always looking for ways to improve the platform based on community feedback.
        </p>
      )
    },
    {
      question: "My listing isn't showing. What should I do?",
      answer: "If you can't see your listing on the map, you may need to scroll to the bottom of all listings for the next batch to load in."
    },
    {
      question: "The information you have is incorrect, or I'd like to update my listing or photo.<br>How can I do this?",
      answer: "Contact me via <a href='mailto:hello@altgrocery.ca' class='text-blue-600 hover:text-blue-800 underline'>hello@altgrocery.ca</a>, and I will be happy to assist you."
    },
    {
      question: "I'd like to report a bug, a closed business, or a general question.<br>How can I contact you?",
      answer: "In the bottom right-hand corner, you'll see a small apple chat icon where you can message me directly. Alternatively, feel free to email me at <a href='mailto:hello@altgrocery.ca' class='text-blue-600 hover:text-blue-800 underline'>hello@altgrocery.ca</a>"
    },
    {
      question: "I'd like to help, how can I get involved?",
      answer: "This is a community-driven project, and donations are welcome, as funding goes towards development, marketing, and hosting costs since it is 100% bootstrapped. You can also support us by spreading the word about AltGrocery and encouraging local businesses to join the platform."
    },
    {
      question: "Are you open to collaboration?",
      answer: "Absolutely! We're always open to collaborating with like-minded organizations and individuals. Please feel free to reach out to us to explore opportunities."
    }
  ];

  const toggleFAQ = (index) => {
    setOpenFAQIndex(openFAQIndex === index ? null : index);
  };

  return (
    <>
      <Announcement />
      <Layout>
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-extrabold text-gray-900">Our Values</h2>
              <p className="mt-4 text-lg text-gray-500">
              At AltGrocery, we're committed to fostering a sustainable and community-oriented food system where everyone's voice matters. Through crowdsourcing, we empower communities to shape the food landscape by contributing local knowledge and recommendations.
              </p>
            </div>
            <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-6 sm:gap-y-12 lg:gap-x-8">
              {[
                {
                  name: 'Local Support',
                  description: 'We prioritize local farmers and small independent grocers and businesses to strengthen community economies.',
                },
                {
                  name: 'Sustainability',
                  description: 'We promote environmentally friendly practices in food production and distribution.',
                },
                {
                  name: 'Accessibility',
                  description: 'We strive to make alternative grocery options accessible to all community members.',
                },
                {
                  name: 'Transparency',
                  description: 'We believe in clear, honest information about food sources and practices.',
                },
                {
                  name: 'Diversity',
                  description: 'We celebrate and promote diverse food cultures and traditions.',
                },
                {
                  name: 'Innovation',
                  description: 'We continuously seek new ways to improve the alternative grocery ecosystem.',
                },
                {
                  name: 'Engagement',
                  description: 'Where businesses that have relied on word-of-mouth. We want to help farmers and indie grocers have a digital presence to connect to a new-gen of customers.',
                },
                {
                  name: 'Community Contribution',
                  description: 'Your collective input matters. By sharing recommendations, users help create a richer, more inclusive food network for everyone.',
                },
              ].map((value) => (
                <div key={value.name} className="bg-green-50 rounded-lg p-6 hover:shadow-md transition-shadow duration-300">
                  <dt className="text-lg leading-6 font-medium text-gray-900">{value.name}</dt>
                  <dd className="mt-2 text-base text-gray-500">{value.description}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>

        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
            <div className="space-y-12">
              <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Meet Our Team</h2>
                <p className="text-xl text-gray-500">
                  Our team is passionate about revolutionizing the grocery industry and supporting local communities.
                </p>
              </div>
              <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-1 sm:gap-16 sm:space-y-0 lg:max-w-5xl">
                {[
                  {
                    name: 'Tom Pischel',
                    role: 'Founder / Innovator',
                    imageUrl: 'https://tbvywbqdzxskcrephgby.supabase.co/storage/v1/object/public/photos/1721052754674.jpeg',
                    bio: 'Tom has over 15 years of experience in the big data and hyperlocal industry and is passionate about building innovative technology that positively impacts people.',
                  },
                ].map((person) => (
                  <li key={person.name} className="mx-auto max-w-xs">
                    <div className="space-y-6">
                      <img className="mx-auto h-40 w-40 rounded-full xl:w-56 xl:h-56 object-cover shadow-lg" src={person.imageUrl} alt="" />
                      <div className="space-y-2">
                        <div className="text-lg leading-6 font-medium space-y-1">
                          <h3 className="text-gray-900">{person.name}</h3>
                          <p className="text-green-600">{person.role}</p>
                        </div>
                        <ul className="flex justify-center space-x-5">
                          <li>
                            <a href="https://www.linkedin.com/in/tompischel/" className="text-gray-400 hover:text-gray-500 transition-colors duration-300">
                              <span className="sr-only">LinkedIn</span>
                              <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                <path
                                  fillRule="evenodd"
                                  d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="text-base">
                        <p className="text-gray-500">{person.bio}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
            <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
              <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl mb-8">
                Frequently Asked Questions
              </h2>
              <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                {faqs.map((faq, index) => (
                  <FAQItem
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                    isOpen={openFAQIndex === index}
                    onToggle={() => toggleFAQ(index)}
                  />
                ))}
              </dl>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default About;
