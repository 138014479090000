import React from 'react';
import { MapIcon, ListBulletIcon } from '@heroicons/react/24/outline';

const BottomNavigation = ({ activeView, toggleView }) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 z-50">
      <div className="flex justify-around">
        <button
          onClick={() => toggleView('listView')}
          className={`flex flex-col items-center justify-center w-1/2 py-2 ${
            activeView === 'listView' 
              ? 'text-gray-900' 
              : 'text-gray-500 hover:text-gray-900'
          }`}
        >
          <ListBulletIcon className="h-6 w-6" />
          <span className="text-xs mt-1">List</span>
        </button>
        <button
          onClick={() => toggleView('mapView')}
          className={`flex flex-col items-center justify-center w-1/2 py-2 ${
            activeView === 'mapView' 
              ? 'text-gray-900' 
              : 'text-gray-500 hover:text-gray-900'
          }`}
        >
          <MapIcon className="h-6 w-6" />
          <span className="text-xs mt-1">Map</span>
        </button>
      </div>
    </div>
  );
};

export default BottomNavigation;
