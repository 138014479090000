import React, { memo, useState, useEffect } from 'react';
import Place from './Place';
import TagBadgeList from './TagBadgeList';

const ListView = memo(({
  filteredPlaces,
  totalPlaces,
  handlePlaceHover,
  handlePlaceClick,
  loading,
  showNoResults,
  filters,
  onTagRemove,
  favorites,
  addFavorite,
  removeFavorite,
  user,
  onShare,
  sortOption
}) => {
  const [sortedPlaces, setSortedPlaces] = useState(filteredPlaces);
  const [isSorting, setIsSorting] = useState(false);

  useEffect(() => {
    setIsSorting(true);
    const timer = setTimeout(() => {
      setSortedPlaces(filteredPlaces);
      setIsSorting(false);
    }, 300);
    return () => clearTimeout(timer);
  }, [filteredPlaces, sortOption]);

  const renderPlacesList = () => (
    <div className="places-grid pb-20 md:pb-0">
      {sortedPlaces.map((place) => (
        <div 
          key={place.id} 
          className="place-card-wrapper"
        >
          <Place 
            place={place}
            onPlaceHover={handlePlaceHover}
            onPlaceClick={handlePlaceClick}
            onPhotoClick={() => {
              handlePlaceClick(place);
            }}
            isFavorite={favorites.some(fav => fav.id === place.id)}
            addFavorite={addFavorite}
            removeFavorite={removeFavorite}
            isSignedIn={!!user}
            onShare={onShare}
          />
        </div>
      ))}
    </div>
  );

  const renderLoadingState = () => (
    <div className="places-grid pb-20 md:pb-0">
      {[1, 2, 3, 4].map((_, index) => (
        <div key={index} className="place-card-wrapper">
          <div className="place-card animate-pulse">
            <div className="place-card-image-container bg-gray-200"></div>
            <div className="place-card-content">
              <div className="h-4 bg-gray-200 rounded w-1/4 mb-2"></div>
              <div className="h-6 bg-gray-200 rounded w-3/4 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-2/3"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderNoResults = () => (
    <div className="flex flex-col items-center justify-center p-8 text-center pb-20 md:pb-8">
      <svg 
        className="w-16 h-16 text-gray-400 mb-4" 
        xmlns="http://www.w3.org/2000/svg" 
        fill="none" 
        viewBox="0 0 24 24" 
        strokeWidth={1.5} 
        stroke="currentColor"
      >
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" 
        />
      </svg>
      <h3 className="text-lg font-semibold text-gray-900 mb-2">
        No results found
      </h3>
      <p className="text-gray-600 max-w-sm">
        Try adjusting your filters or exploring a different area.
      </p>
    </div>
  );

  return (
    <div className="h-full overflow-y-auto bg-gray-50">
      {Object.values(filters).some(value => value) && (
        <div className="px-4 py-2 bg-white border-b border-gray-200">
          <TagBadgeList filters={filters} onTagRemove={onTagRemove} />
        </div>
      )}
      
      {loading && sortedPlaces.length === 0 ? (
        renderLoadingState()
      ) : sortedPlaces.length > 0 ? (
        <div 
          className="transition-opacity duration-300"
          style={{ opacity: isSorting ? 0.5 : 1 }}
        >
          {renderPlacesList()}
        </div>
      ) : showNoResults ? (
        renderNoResults()
      ) : null}
    </div>
  );
});

export default ListView;
