import { useState, useEffect, useCallback } from 'react';
import { supabase } from '../config/supabaseClient';

const ITEMS_PER_PAGE = 500;

const defaultFilters = {
  province: '',
  city: '',
  category: '',
  subCategory: ''
};

export default function usePlaces(initialFilters) {
  const [places, setPlaces] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPlaces, setTotalPlaces] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortOption, setSortOption] = useState('recent');
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [filters, setFilters] = useState(initialFilters);
  const [searchQuery, setSearchQuery] = useState('');

  // Save province to cookie whenever it changes
  useEffect(() => {
    if (filters.province) {
      document.cookie = `lastProvince=${filters.province};path=/;max-age=31536000`; // 1 year
    }
  }, [filters.province]);

  const fetchCategories = useCallback(async () => {
    try {
      // Start with a query that joins places with categories
      let query = supabase
        .from('places')
        .select(`
          cat_id,
          categories:cat_id(id, name)
        `);

      // Apply current filters to get relevant categories
      if (filters.province) {
        query = query.eq('province', filters.province);
      }
      if (filters.city) {
        query = query.eq('city', filters.city);
      }

      const { data: placesData, error: placesError } = await query;

      if (placesError) {
        console.error('Error fetching categories from places:', placesError);
        return;
      }

      if (placesData) {
        // Create a map to deduplicate categories while preserving their structure
        const categoryMap = new Map();
        placesData.forEach(place => {
          if (place.categories) {
            categoryMap.set(place.categories.id, place.categories);
          }
        });

        // Convert map values to array and sort by name
        const uniqueCategories = Array.from(categoryMap.values())
          .sort((a, b) => a.name.localeCompare(b.name));

        setCategories(uniqueCategories);

        // If a category is selected, fetch its subcategories
        if (filters.category) {
          const selectedCategory = uniqueCategories.find(cat => cat.name === filters.category);
          if (selectedCategory) {
            // Query places to get available subcategories for the selected category
            let subQuery = supabase
              .from('places')
              .select(`
                sub_cat_id,
                subcategories:sub_cat_id(id, name)
              `)
              .eq('cat_id', selectedCategory.id);

            if (filters.province) {
              subQuery = subQuery.eq('province', filters.province);
            }
            if (filters.city) {
              subQuery = subQuery.eq('city', filters.city);
            }

            const { data: subCatData, error: subCatError } = await subQuery;

            if (subCatError) {
              console.error('Error fetching subcategories:', subCatError);
              return;
            }

            if (subCatData) {
              // Create a map to deduplicate subcategories
              const subCategoryMap = new Map();
              subCatData.forEach(place => {
                if (place.subcategories) {
                  subCategoryMap.set(place.subcategories.id, place.subcategories);
                }
              });

              // Convert map values to array and sort by name
              const uniqueSubCategories = Array.from(subCategoryMap.values())
                .sort((a, b) => a.name.localeCompare(b.name));

              setSubCategories(uniqueSubCategories);
            }
          }
        } else {
          setSubCategories([]);
        }
      }
    } catch (error) {
      console.error('Error in fetchCategories:', error);
    }
  }, [filters]);

  const fetchPlaces = useCallback(async (page = 0, sort = sortOption, search = searchQuery) => {
    setLoading(true);

    try {
      let query = supabase
        .from('places')
        .select(`
          *,
          categories:cat_id(id, name),
          subcategories:sub_cat_id(id, name)
        `, { count: 'exact' });

      // Apply search first if present
      if (search?.trim()) {
        query = query.or(`name.ilike.%${search}%,description.ilike.%${search}%,address.ilike.%${search}%,city.ilike.%${search}%,province.ilike.%${search}%`);
      } else {
        // Only apply filters if no search is active
        if (filters.province) {
          query = query.eq('province', filters.province);
        }
        if (filters.city) {
          query = query.eq('city', filters.city);
        }
        if (filters.category) {
          // Find the category ID from the categories array
          const selectedCategory = categories.find(cat => cat.name === filters.category);
          if (selectedCategory) {
            query = query.eq('cat_id', selectedCategory.id);
          }
        }
        if (filters.subCategory) {
          // Find the subcategory ID from the subcategories array
          const selectedSubCategory = subCategories.find(subCat => subCat.name === filters.subCategory);
          if (selectedSubCategory) {
            query = query.eq('sub_cat_id', selectedSubCategory.id);
          }
        }
      }

      // Apply sorting
      switch (sort) {
        case 'a-z':
          query = query.order('name', { ascending: true });
          break;
        case 'z-a':
          query = query.order('name', { ascending: false });
          break;
        default: // 'recent'
          query = query.order('created_at', { ascending: false });
      }

      // Apply pagination
      query = query.range(page * ITEMS_PER_PAGE, (page + 1) * ITEMS_PER_PAGE - 1);

      const { data, error, count } = await query;

      if (error) {
        console.error('Error fetching places:', error);
        return;
      }

      if (data) {
        // Transform the data to include category and subcategory names
        const transformedData = data.map(place => ({
          ...place,
          category: place.categories?.name || place.category,
          sub_category: place.subcategories?.name || place.sub_category
        }));

        setPlaces(transformedData);
        if (count !== null) {
          setTotalPlaces(count);
        }
      }
    } catch (error) {
      console.error('Error in fetchPlaces:', error);
    } finally {
      setLoading(false);
    }
  }, [filters, sortOption, searchQuery, categories, subCategories]);

  // Reset everything when search or filters change
  useEffect(() => {
    setCurrentPage(0);
    fetchPlaces(0);
  }, [fetchPlaces, filters, searchQuery]);

  // Initial categories fetch
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const handleSort = useCallback((option) => {
    setSortOption(option);
    setCurrentPage(0);
    fetchPlaces(0, option);
  }, [fetchPlaces]);

  const updateFilters = useCallback((newFilters) => {
    if (newFilters.category !== filters.category) {
      newFilters.subCategory = '';
    }
    setFilters(newFilters);
    setCurrentPage(0);
    // Clear search when applying filters
    if (searchQuery) {
      setSearchQuery('');
    }
  }, [filters.category, searchQuery]);

  const handleSearch = useCallback((query) => {
    setSearchQuery(query);
    setCurrentPage(0);
    // Clear filters except province when searching
    if (Object.values(filters).some(v => v)) {
      setFilters(prev => ({
        ...defaultFilters,
        province: prev.province // Keep the province
      }));
    }
  }, [filters]);

  const changePage = useCallback((page) => {
    setCurrentPage(page);
    fetchPlaces(page, sortOption);
  }, [fetchPlaces, sortOption]);

  const resetAll = useCallback(() => {
    setCurrentPage(0);
    setSortOption('recent');
    setSearchQuery('');
    setPlaces([]);
    // Keep categories and subCategories
    setTotalPlaces(0);
    // Keep the province when resetting
    setFilters(prev => ({
      ...defaultFilters,
      province: prev.province
    }));
    fetchPlaces(0, 'recent', '');
  }, [fetchPlaces]);

  const totalPages = Math.ceil(totalPlaces / ITEMS_PER_PAGE);

  return {
    places,
    loading,
    totalPlaces,
    currentPage,
    totalPages,
    sortOption,
    handleSort,
    changePage,
    updateFilters,
    categories,
    subCategories,
    filters,
    resetAll,
    searchQuery,
    handleSearch,
  };
}
