import React from 'react';
import PublicRecommendForm from '../components/PublicRecommendForm';
import Layout from '../components/Layout';
import Announcement from '../components/Announcement';

const PublicRecommend = () => {
  return (
    <>
      <Announcement />
      <Layout>
        <div className="container mx-auto px-2 sm:px-4 py-8">
          <h1 className="text-3xl font-bold mb-6">Recommend a Place</h1>
          <p className="mb-6">
            Know a great local business or place that should be on AltGrocery? Let us know! 
            Your recommendation will be reviewed and, if approved, added to our listings.
          </p>
          <div className="bg-white border-gray-200 rounded px-2 sm:px-8 pt-6 pb-8 mb-4">
            <PublicRecommendForm />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default PublicRecommend;
