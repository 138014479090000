import { useRef, useState, useEffect, useCallback } from 'react';
import maplibregl from 'maplibre-gl';

const useMapInitialization = (lng, lat, zoom) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [mapInitialized, setMapInitialized] = useState(false);

  const initializeMap = useCallback(() => {
    if (!mapInitialized && mapContainer.current) {
      map.current = new maplibregl.Map({
        container: mapContainer.current,
        style: 'https://api.maptiler.com/maps/streets/style.json?key=marSfFRGS8cODzM6jzY7',
        center: [lng, lat],
        zoom: zoom,
      });

      // Disable scroll wheel zooming
      map.current.scrollZoom.disable();

      setMapInitialized(true);
    } else if (map.current) {
      map.current.resize();
    }
  }, [lng, lat, zoom, mapInitialized]);

  useEffect(() => {
    initializeMap();
  }, [initializeMap]);

  const handleResize = useCallback(() => {
    if (map.current) {
      map.current.resize();
    }
  }, []);

  return { mapContainer, map, mapInitialized, initializeMap, handleResize };
};

export default useMapInitialization;
