import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import toast from 'react-hot-toast';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import altGroceryLogo from '../assets/altgrocery3.png';
import { getProvinceForMapUrl } from '../utils/provinceUtils';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Header({ isEmbedded = false }) {
  const { user, signOut } = useAuth();
  const location = useLocation();
  const { countryCode } = useParams();
  const navigate = useNavigate();
  const [preferredProvince, setPreferredProvince] = useState(getProvinceForMapUrl());

  useEffect(() => {
    setPreferredProvince(getProvinceForMapUrl());
  }, [location]);

  const isMapPage = location.pathname.includes('/map');

  const handleSignOut = async () => {
    try {
      await signOut();
      toast.success('Signed out successfully');
    } catch (error) {
      toast.error('Error signing out');
    }
  };

  const handleHomeClick = (e) => {
    e.preventDefault();
    if (user) {
      navigate(`/${countryCode}/`);
    } else {
      navigate('/');
    }
  };

  const navigation = [
    { name: 'Home', href: user ? `/${countryCode}/` : '/', current: location.pathname === `/${countryCode}/` || location.pathname === '/', onClick: handleHomeClick },
    { name: 'Map', href: user ? `/${countryCode}/map` : `/map?province=${preferredProvince}`, current: isMapPage },
    { name: 'Recommend', href: user ? `/${countryCode}/recommend` : '/recommend', current: location.pathname === `/${countryCode}/recommend` || location.pathname === '/recommend' },
    { name: 'About', href: '/about', current: location.pathname === '/about' },
    { 
      name: (
        <span className="relative inline-flex items-center pr-6">
          Roadmap
          <span className="absolute -top-2 right-0 text-[0.6rem] font-bold text-green-500 tracking-tight">New</span>
        </span>
      ), 
      href: '/roadmap', 
      current: location.pathname === '/roadmap' 
    },
  ];

  const headerClass = isMapPage
    ? "bg-white shadow-[0_2px_4px_rgba(0,0,0,0.1)] sticky top-0 w-full"
    : isEmbedded
    ? "bg-white"
    : "bg-white shadow-[0_2px_4px_rgba(0,0,0,0.1)] sticky top-0";

  const containerStyle = isMapPage
    ? { maxWidth: '160rem' }
    : { maxWidth: '97rem' };

  return (
    <Disclosure as="nav" className={`${headerClass} z-[9999] w-full`}>
      {({ open }) => (
        <>
          <div className="w-full">
            <div className="mx-auto px-4 sm:px-6 lg:px-8 w-full" style={containerStyle}>
              <div className="flex justify-between h-16">
                <div className="flex items-center">
                  <Link to={user ? `/${countryCode}/` : '/'} className="flex-shrink-0" onClick={handleHomeClick}>
                    <img className="h-8 w-auto" src={altGroceryLogo} alt="AltGrocery" />
                  </Link>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  <div className="flex space-x-8">
                    {navigation.map((item) => (
                      <Link
                        key={typeof item.name === 'string' ? item.name : 'Roadmap'}
                        to={item.href}
                        onClick={item.onClick}
                        className={classNames(
                          item.current
                            ? 'border-[#F7CD65] text-gray-900 scale-105'
                            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 hover:scale-105',
                          'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium transition-all duration-200 ease-in-out transform'
                        )}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  {user && (
                    <Menu as="div" className="ml-3 relative">
                      <div>
                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={`/${countryCode}/dashboard`}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                Dashboard
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={handleSignOut}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block w-full text-left px-4 py-2 text-sm text-gray-700'
                                )}
                              >
                                Sign out
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-2 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={typeof item.name === 'string' ? item.name : 'Roadmap'}
                  as={Link}
                  to={item.href}
                  onClick={item.onClick}
                  className={classNames(
                    item.current
                      ? 'bg-[#F7CD65] border-[#F7CD65] text-gray-900'
                      : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                    'block border-l-4 py-2.5 pl-3 pr-4 text-[0.8rem] font-medium relative'
                  )}
                >
                  {typeof item.name === 'string' ? (
                    item.name
                  ) : (
                    <span className="relative inline-flex items-center pr-6">
                      Roadmap
                      <span className="absolute -top-2 right-0 text-[0.6rem] font-bold text-green-500 tracking-tight">New</span>
                    </span>
                  )}
                </Disclosure.Button>
              ))}
            </div>
            {user && (
              <div className="border-t border-gray-200 pb-2 pt-2">
                <div className="flex items-center px-4 py-2">
                  <div className="flex-shrink-0">
                    <img
                      className="h-8 w-8 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-[0.8rem] font-medium text-gray-800">{user.name}</div>
                    <div className="text-[0.7rem] font-medium text-gray-500">{user.email}</div>
                  </div>
                </div>
                <div className="mt-2 space-y-1">
                  <Disclosure.Button
                    as={Link}
                    to={`/${countryCode}/dashboard`}
                    className="block px-4 py-2.5 text-[0.8rem] font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  >
                    Dashboard
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="button"
                    onClick={handleSignOut}
                    className="block w-full text-left px-4 py-2.5 text-[0.8rem] font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  >
                    Sign out
                  </Disclosure.Button>
                </div>
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export default Header;
