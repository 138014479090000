import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import maplibregl from 'maplibre-gl';
import { MagnifyingGlassIcon, FunnelIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useLocationContext } from '../context/LocationContext';
import { useFavorites } from '../context/FavoritesContext';
import { useAuth } from '../context/AuthContext';
import Header from '../components/Header';
import BottomNavigation from '../components/BottomNavigation';
import MapView from '../components/MapView';
import ListView from '../components/ListView';
import CountryDetector from '../components/CountryDetector';
import Share from '../components/Share';
import Announcement from '../components/Announcement';
import MapFilters from '../components/MapFilters';
import { createPopupContent } from '../components/MapPopup';
import useMapInitialization from '../hooks/useMapInitialization';
import usePlaces from '../hooks/usePlaces';
import useMapFilters from '../hooks/useMapFilters';
import useResizeObserver from '../hooks/useResizeObserver';
import '../placeGrid.css';
import '../mapStyles.css';

const MapPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [lng] = useState(-79.3832);
  const [lat] = useState(43.6532);
  const [zoom] = useState(9);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [activeView, setActiveView] = useState(isMobile ? 'listView' : 'mapView');
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [shouldUpdateMap, setShouldUpdateMap] = useState(false);
  const [currentPopup, setCurrentPopup] = useState(null);
  const [showShareModal, setShowShareModal] = useState(false);
  const [sharePlace, setSharePlace] = useState(null);

  const { location: detectedLocation } = useLocationContext();
  const { favorites, addFavorite, removeFavorite } = useFavorites();
  const { user } = useAuth();

  const { mapContainer, map, initializeMap, handleResize: mapHandleResize } = useMapInitialization(lng, lat, zoom);
  
  const initialFilters = useMemo(() => {
    const provinceParam = searchParams.get('province');
    const detectedProvince = detectedLocation.province;
    let province = provinceParam || detectedProvince || 'Ontario';

    if (province.toLowerCase() !== 'ontario' && !provinceParam && !detectedProvince) {
      province = 'Ontario';
    }

    return {
      province,
      city: searchParams.get('city') || '',
      category: searchParams.get('category') || '',
      subCategory: searchParams.get('subCategory') || '',
    };
  }, [detectedLocation.province, searchParams]);

  const {
    places: displayPlaces,
    loading,
    totalPlaces,
    currentPage,
    totalPages,
    sortOption,
    handleSort,
    changePage,
    updateFilters,
    categories,
    subCategories,
    filters,
    resetAll,
    searchQuery,
    handleSearch,
  } = usePlaces(initialFilters);

  const {
    provinces,
    cities,
    handleProvinceChange,
    handleCityChange,
    handleCategoryChange,
    handleSubCategoryChange,
    handleTagRemove,
    resetFilters,
    fetchCities,
  } = useMapFilters(initialFilters, updateFilters, categories, subCategories);

  const updateSearchParams = useCallback((newFilters) => {
    const params = new URLSearchParams(searchParams);
    
    Object.entries(newFilters).forEach(([key, value]) => {
      if (value) {
        params.set(key.toLowerCase(), value);
      } else {
        params.delete(key.toLowerCase());
      }
    });

    setSearchParams(params);
  }, [searchParams, setSearchParams]);

  const handleProvinceChangeWithParams = useCallback((province) => {
    handleProvinceChange(province);
    updateSearchParams({ ...filters, province, city: '', category: '', subCategory: '' });
  }, [filters, handleProvinceChange, updateSearchParams]);

  const handleCityChangeWithParams = useCallback((city) => {
    handleCityChange(city);
    updateSearchParams({ ...filters, city });
  }, [filters, handleCityChange, updateSearchParams]);

  const handleCategoryChangeWithParams = useCallback((category) => {
    handleCategoryChange(category);
    updateSearchParams({ ...filters, category, subCategory: '' });
  }, [filters, handleCategoryChange, updateSearchParams]);

  const handleSubCategoryChangeWithParams = useCallback((subCategory) => {
    handleSubCategoryChange(subCategory);
    updateSearchParams({ ...filters, subCategory });
  }, [filters, handleSubCategoryChange, updateSearchParams]);

  const handleTagRemoveAndUpdateFilters = useCallback((key) => {
    handleTagRemove(key);
    
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(key.toLowerCase());
    setSearchParams(newSearchParams);
  }, [handleTagRemove, searchParams, setSearchParams]);

  const handleReset = useCallback(() => {
    setSearchParams(new URLSearchParams());
    resetFilters();
    resetAll();
    setIsFilterDrawerOpen(false);
  }, [resetAll, setSearchParams, resetFilters]);

  useEffect(() => {
    if (filters.province) {
      fetchCities(filters.province);
    }
  }, [filters.province, fetchCities]);

  const toggleView = useCallback((newView) => {
    setActiveView(newView);
    if (newView === 'mapView') {
      setTimeout(() => {
        if (map.current) {
          map.current.resize();
        }
      }, 300);
    }
  }, [map]);

  const toggleFilterDrawer = useCallback(() => {
    setIsFilterDrawerOpen((prev) => !prev);
  }, []);

  const handleClearCategory = useCallback(() => {
    handleCategoryChangeWithParams('');
  }, [handleCategoryChangeWithParams]);

  const handleClearProvince = useCallback(() => {
    handleProvinceChangeWithParams('');
  }, [handleProvinceChangeWithParams]);

  const handleShare = useCallback((place) => {
    setSharePlace(place);
    setShowShareModal(true);
  }, []);

  const handlePlaceClick = useCallback((place) => {
    if (map.current && place.lng && place.lat) {
      if (currentPopup) {
        currentPopup.remove();
      }

      const popupContent = createPopupContent(place);
      const popup = new maplibregl.Popup({
        closeButton: true,
        className: 'custom-popup',
      })
        .setLngLat([place.lng, place.lat])
        .setHTML(popupContent)
        .addTo(map.current);

      popup.on('close', () => setCurrentPopup(null));

      const popupElement = popup.getElement();
      popupElement.querySelector('.custom-popup-close').addEventListener('click', () => popup.remove());

      setCurrentPopup(popup);

      const currentZoom = map.current.getZoom();
      const targetZoom = Math.max(currentZoom, 15);

      map.current.flyTo({
        center: [place.lng, place.lat],
        zoom: targetZoom,
        duration: 1000,
        essential: true
      });

      if (activeView === 'listView' && isMobile) {
        toggleView('mapView');
      }
    }
  }, [map, currentPopup, activeView, isMobile, toggleView]);

  const handleResize = useCallback(() => {
    const newIsMobile = window.innerWidth < 768;
    if (newIsMobile !== isMobile) {
      setIsMobile(newIsMobile);
      setActiveView(newIsMobile ? 'listView' : 'mapView');
      
      setTimeout(() => {
        if (map.current) {
          map.current.resize();
          const center = map.current.getCenter();
          const zoom = map.current.getZoom();
          map.current.setCenter(center);
          map.current.setZoom(zoom);
        }
      }, 300);
    }
    mapHandleResize();
  }, [isMobile, map, mapHandleResize]);

  const { observe, unobserve } = useResizeObserver(handleResize);

  // Initialize map when component mounts
  useEffect(() => {
    if (activeView === 'mapView') {
      initializeMap();
    }
  }, [initializeMap, activeView]);

  // Set map loaded state
  useEffect(() => {
    if (map.current) {
      map.current.on('load', () => {
        setMapLoaded(true);
      });
    }
  }, [map]);

  useEffect(() => {
    const currentMapContainer = mapContainer.current;
    if (currentMapContainer) {
      observe(currentMapContainer);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      if (currentMapContainer) {
        unobserve(currentMapContainer);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [observe, unobserve, handleResize, mapContainer]);

  useEffect(() => {
    if (map.current && activeView === 'mapView') {
      setTimeout(() => {
        map.current.resize();
        const center = map.current.getCenter();
        const zoom = map.current.getZoom();
        map.current.setCenter(center);
        map.current.setZoom(zoom);
      }, 300);
    }
  }, [activeView, map]);

  useEffect(() => {
    if (mapLoaded && displayPlaces.length > 0) {
      setShouldUpdateMap(true);
    }
  }, [mapLoaded, displayPlaces]);

  const showNoResults = useMemo(() => displayPlaces.length === 0 && !loading, [displayPlaces, loading]);

  const currentProvince = filters.province || 'All Provinces';

  const renderPagination = () => {
    if (totalPages <= 1) return null;

    const maxButtons = 3;
    let startPage = Math.max(0, currentPage - Math.floor(maxButtons / 2));
    let endPage = Math.min(totalPages - 1, startPage + maxButtons - 1);

    if (endPage - startPage + 1 < maxButtons) {
      startPage = Math.max(0, endPage - maxButtons + 1);
    }

    return (
      <div className="flex items-center justify-center space-x-1 py-2 bg-white border-t border-b border-gray-200">
        <div className="flex items-center space-x-1">
          <button
            onClick={() => changePage(Math.max(0, currentPage - 1))}
            disabled={currentPage === 0}
            className={`p-1 rounded ${
              currentPage === 0
                ? 'text-gray-400 cursor-not-allowed'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
            aria-label="Previous page"
          >
            <ChevronLeftIcon className="h-4 w-4" />
          </button>

          {startPage > 0 && (
            <>
              <button
                onClick={() => changePage(0)}
                className="min-w-[1.5rem] px-1 py-0.5 text-xs rounded hover:bg-gray-100"
              >
                1
              </button>
              {startPage > 1 && <span className="text-gray-500 text-xs">...</span>}
            </>
          )}

          {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(
            (page) => (
              <button
                key={page}
                onClick={() => changePage(page)}
                className={`min-w-[1.5rem] px-1 py-0.5 text-xs rounded ${
                  currentPage === page
                    ? 'bg-gray-800 text-white'
                    : 'hover:bg-gray-100'
                }`}
              >
                {page + 1}
              </button>
            )
          )}

          {endPage < totalPages - 1 && (
            <>
              {endPage < totalPages - 2 && <span className="text-gray-500 text-xs">...</span>}
              <button
                onClick={() => changePage(totalPages - 1)}
                className="min-w-[1.5rem] px-1 py-0.5 text-xs rounded hover:bg-gray-100"
              >
                {totalPages}
              </button>
            </>
          )}

          <button
            onClick={() => changePage(Math.min(totalPages - 1, currentPage + 1))}
            disabled={currentPage === totalPages - 1}
            className={`p-1 rounded ${
              currentPage === totalPages - 1
                ? 'text-gray-400 cursor-not-allowed'
                : 'text-gray-600 hover:bg-gray-100'
            }`}
            aria-label="Next page"
          >
            <ChevronRightIcon className="h-4 w-4" />
          </button>
        </div>
      </div>
    );
  };

  const renderListView = () => (
    <div className="flex-1 flex flex-col overflow-hidden">
      <div className="bg-white border-b border-gray-200 flex-shrink-0">
        <div className="p-3">
          <div className="flex justify-between items-center">
            <p className="text-sm text-gray-600">
              {currentProvince}: Showing <span className="font-medium">{displayPlaces.length}</span> of <span className="font-medium">{totalPlaces}</span>
            </p>
            <div className="relative">
              <select 
                value={sortOption} 
                onChange={(e) => handleSort(e.target.value)}
                className="appearance-none pl-3 pr-8 py-1 border border-gray-300 rounded-md text-sm bg-white cursor-pointer focus:outline-none focus:ring-1 focus:ring-[#FFCC4A]"
              >
                <option value="recent">Recent</option>
                <option value="a-z">A-Z</option>
                <option value="z-a">Z-A</option>
              </select>
              <ChevronDownIcon className="absolute right-2 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-500 pointer-events-none" />
            </div>
          </div>
        </div>
        {renderPagination()}
      </div>
      <div className={`flex-1 overflow-y-auto ${isMobile ? 'pb-16' : ''}`}>
        <ListView
          filteredPlaces={displayPlaces}
          totalPlaces={totalPlaces}
          onTagRemove={handleTagRemoveAndUpdateFilters}
          handlePlaceClick={handlePlaceClick}
          loading={loading}
          showNoResults={showNoResults}
          filters={filters}
          updateFilters={updateFilters}
          favorites={favorites}
          addFavorite={addFavorite}
          removeFavorite={removeFavorite}
          user={user}
          onShare={handleShare}
          sortOption={sortOption}
        />
      </div>
    </div>
  );

  return (
    <>
      <Announcement />
      <div className="flex flex-col h-screen font-custom bg-gray-50">
        <CountryDetector />
        <Header isMobile={isMobile} />
        
        <div className="sticky top-0 z-50 bg-white border-b border-gray-200">
          <div className="p-3">
            {isMobile ? (
              <>
                <div className="flex items-center gap-2">
                  <div className="relative flex-1">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => handleSearch(e.target.value)}
                      placeholder="Search by Business Name"
                      className="w-full pl-4 pr-9 py-2 border border-gray-300 rounded-lg text-sm focus:outline-none focus:ring-1 focus:ring-[#FFCC4A]"
                    />
                    <MagnifyingGlassIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
                  </div>
                  <button
                    onClick={handleReset}
                    className="px-3 py-2 text-sm bg-gray-800 text-white hover:bg-gray-700 rounded-lg transition-colors duration-200"
                  >
                    Reset
                  </button>
                  <button
                    onClick={toggleFilterDrawer}
                    className={`flex items-center px-3 py-2 text-sm rounded-lg transition-colors duration-200 ${
                      isFilterDrawerOpen 
                        ? 'bg-[#FFCC4A] text-black hover:bg-[#FFCC4A]/90' 
                        : 'text-gray-700 hover:bg-gray-100'
                    }`}
                  >
                    <FunnelIcon className="h-4 w-4 mr-1" />
                    Filters
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center gap-2">
                  <div className="relative flex-1">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => handleSearch(e.target.value)}
                      placeholder="Search by Business Name"
                      className="w-full pl-4 pr-9 py-2 border border-gray-300 rounded-lg text-sm focus:outline-none focus:ring-1 focus:ring-[#FFCC4A]"
                    />
                    <MagnifyingGlassIcon className="absolute right-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400 pointer-events-none" />
                  </div>
                  <button
                    onClick={handleReset}
                    className="shrink-0 px-3 py-2 text-sm bg-gray-800 text-white hover:bg-gray-700 rounded-lg transition-colors duration-200"
                  >
                    Reset
                  </button>
                </div>
              </>
            )}

            <MapFilters
              isMobile={isMobile}
              isFilterDrawerOpen={isFilterDrawerOpen}
              filters={filters}
              provinces={provinces}
              cities={cities}
              filteredCategories={categories}
              filteredSubCategories={subCategories}
              handleProvinceChange={handleProvinceChangeWithParams}
              handleCityChange={handleCityChangeWithParams}
              handleCategoryChange={handleCategoryChangeWithParams}
              handleSubCategoryChange={handleSubCategoryChangeWithParams}
              handleClearProvince={handleClearProvince}
              handleClearCategory={handleClearCategory}
            />
          </div>
        </div>

        <div className="flex-1 relative overflow-hidden">
          {isMobile ? (
            <div className="h-full flex flex-col">
              <div 
                className={`absolute inset-0 transition-transform duration-300 flex flex-col ${
                  activeView === 'mapView' ? 'translate-x-0' : 'translate-x-full'
                }`}
              >
                <MapView
                  mapContainer={mapContainer}
                  map={map}
                  filteredPlaces={displayPlaces}
                  handlePlaceClick={handlePlaceClick}
                  createPopupContent={createPopupContent}
                  favorites={favorites}
                  mapLoaded={mapLoaded}
                  shouldUpdateMap={shouldUpdateMap}
                  setShouldUpdateMap={setShouldUpdateMap}
                />
              </div>
              <div 
                className={`absolute inset-0 bg-gray-50 transition-transform duration-300 flex flex-col ${
                  activeView === 'listView' ? 'translate-x-0' : '-translate-x-full'
                }`}
              >
                {renderListView()}
              </div>
              <BottomNavigation
                activeView={activeView}
                toggleView={toggleView}
              />
            </div>
          ) : (
            <div className="flex h-full">
              <div className="w-1/3 bg-gray-50 border-r border-gray-200 overflow-hidden flex flex-col">
                {renderListView()}
              </div>
              <div className="w-2/3 relative">
                <MapView
                  mapContainer={mapContainer}
                  map={map}
                  filteredPlaces={displayPlaces}
                  handlePlaceClick={handlePlaceClick}
                  createPopupContent={createPopupContent}
                  favorites={favorites}
                  mapLoaded={mapLoaded}
                  shouldUpdateMap={shouldUpdateMap}
                  setShouldUpdateMap={setShouldUpdateMap}
                />
              </div>
            </div>
          )}
        </div>
        {showShareModal && (
          <Share 
            place={sharePlace} 
            onClose={() => {
              setShowShareModal(false);
              setSharePlace(null);
            }} 
          />
        )}
      </div>
    </>
  );
};

export default MapPage;
