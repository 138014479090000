export const createPopupContent = (place) => {
  const contactIcons = [];

  if (place.phone) {
    contactIcons.push(`
      <a href="tel:${place.phone}" class="text-gray-500 hover:text-gray-700 inline-flex items-center justify-center" title="${place.phone}">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-[18px] h-[18px]">
          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
        </svg>
      </a>
    `);
  }

  if (place.email) {
    contactIcons.push(`
      <a href="mailto:${place.email}" class="text-gray-500 hover:text-gray-700 inline-flex items-center justify-center" title="${place.email}">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-[18px] h-[18px]">
          <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
        </svg>
      </a>
    `);
  }

  if (place.website) {
    contactIcons.push(`
      <a href="${place.website}" target="_blank" rel="noopener noreferrer" class="text-gray-500 hover:text-gray-700 inline-flex items-center justify-center" title="Visit website">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-[18px] h-[18px]">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
        </svg>
      </a>
    `);
  }

  if (place.facebook) {
    contactIcons.push(`
      <a href="${place.facebook}" target="_blank" rel="noopener noreferrer" class="text-gray-500 hover:text-[#1877f2] inline-flex items-center justify-center" title="Visit Facebook page">
        <i class="fab fa-facebook text-[18px]" style="width: 18px; height: 18px; display: flex; align-items: center; justify-content: center;"></i>
      </a>
    `);
  }

  if (place.instagram) {
    contactIcons.push(`
      <a href="${place.instagram}" target="_blank" rel="noopener noreferrer" class="text-gray-500 hover:text-[#e4405f] inline-flex items-center justify-center" title="Visit Instagram profile">
        <i class="fab fa-instagram text-[18px]" style="width: 18px; height: 18px; display: flex; align-items: center; justify-content: center;"></i>
      </a>
    `);
  }

  return `
    <div class="custom-popup">
      <img src="${place.photo || 'https://tbvywbqdzxskcrephgby.supabase.co/storage/v1/object/public/photos/altgrocery-default.webp'}" alt="${place.name}" class="custom-popup-image" />
      <button class="custom-popup-close">
        <i class="fas fa-times"></i>
      </button>
      <div class="custom-popup-content">
        <h3>${place.name}</h3>
        <p>${place.address}</p>
        <div class="flex gap-4 mt-2">
          ${contactIcons.join('')}
          <a 
            href="${place.google_url || `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(place.name + ' ' + place.address)}`}" 
            target="_blank" 
            rel="noopener noreferrer" 
            class="text-gray-500 hover:text-gray-700 inline-flex items-center justify-center"
            title="Get directions"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-[18px] h-[18px]">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  `;
};

export default createPopupContent;
